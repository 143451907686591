/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Heading, Text, Link } from 'theme-ui';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../plugins/storefront/src/components/Breadcrumbs';

const FaqPage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  return (
    <>
      <Helmet
        title={t({
          id: `Faq.Title`,
          message: `FAQ`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `Faq.MetaDescription`,
            message: `FAQ abot hair extensions`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            productTitle={t({
              id: `Faq.BreadcrumbsTitle`,
              message: `FAQ`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="Faq.Heading">FAQ</Trans>
        </Heading>
        <Trans id="Faq.Description">
          <Text as="p" mt={[2, 3]}>
            We are in the process of combining all essential questions and
            answers.
          </Text>
          <Text as="p" mt={[2, 3]}>
            Meanwhile, please feel free to{' '}
            <Link as={GatsbyLink} to="/contact">
              contact us
            </Link>{' '}
            in case of any questions.
          </Text>
        </Trans>
      </Box>
    </>
  );
};

export const FaqPageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default FaqPage;
